<script>
import { defineComponent, ref } from 'vue'
import { Vue3Lottie } from 'vue3-lottie'

import AnimationJSON from '@/assets/lottie/preloader.json'

export default defineComponent({
  name: 'ThePreloader',
  components: { Vue3Lottie },
  setup () {
    return {
      AnimationJSON,
      visible: ref(true)
    }
  }
})
</script>

<template>
  <div id="app-loading-container">
    <transition mode="out-in">
      <div v-if="visible" id="app-loading" class="fixed z-1000 top-0 left-0 right-0 bottom-0 c-blue-bg w-full h-full flex items-center justify-center">
        <Vue3Lottie
          :animation-data="AnimationJSON"
          :anim="true"
          :loop="true"
          :speed="1"
          style="width: 100%; max-width: 400px;"
        />
      </div>
    </transition>
  </div>
</template>
